import React, {useEffect} from 'react'
import ApiService from '../Service/ApiService'
import './dashboardStyle.css'
import moment from 'moment';

const Dashboard = (props) => {

    const itemsPerPage = 10;
    const [dataDash, setDataDash] = React.useState([]);
    const [items, setItems] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(0);
    const [searchResults, setSearchResults] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    const fetchDashboardHandler = React.useCallback(async () => {
       try {
           const dataDashboard = await ApiService.getDashboard();
           setDataDash(dataDashboard);
           setItems([...dataDashboard].splice(0,itemsPerPage));
           console.log("done")
       } catch (error) {
            console.log("not done")
        }
    }, [])

    useEffect(() => {
        void fetchDashboardHandler();
        }, [fetchDashboardHandler]);



    const prevHandler = () => {
        const prevPage = currentPage - 1;
        if(prevPage < 0) return;
        const firstIndex = prevPage * itemsPerPage;
        setItems([...dataDash].splice(firstIndex,itemsPerPage));
        setCurrentPage(prevPage);
    }

    const nextHandler = () => {
        const totalElements = dataDash.length;
        const nextPage = currentPage + 1;
        const firstIndex = nextPage * itemsPerPage;
        if (firstIndex >= totalElements) return;
        setItems([...dataDash].splice(firstIndex, itemsPerPage));
        setCurrentPage(nextPage);
    }

    useEffect(() => {
        const searchHandler = () => {
            if (!props.searchTerm) {
                setSearchResults(items);
            } else {
                const filteredResults = items.filter(item =>
                    item.matricula.includes(props.searchTerm)
                );
                setSearchResults(filteredResults);
            }
        };

        searchHandler();
    }, [props.searchTerm, items]);

    return (
        <div className="col-12">
            <div className="card recent-sales overflow-auto">
                <div className="card-body"
                     style={{
                         marginTop: '70px',
                         marginLeft: '50px',
                         marginRight: '50px',
                         maxHeight: '70vh',
                         minHeight: '70vh'
                     }}>
                    <h5 className="card-title">Historial <span>| Consultas</span></h5>
                    <table className="table table-bordered datatable">
                        <colgroup>
                            <col style={{width: '10%'}}/>
                            <col style={{width: '10%'}}/>
                            <col style={{width: '20%'}}/>
                            <col style={{width: '10%'}}/>
                            <col style={{width: '25%'}}/>
                            <col style={{width: '10%'}}/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th scope="col">Matricula</th>
                            <th scope="col">Metros</th>
                            <th scope="col">Nombre</th>
                            <th scope="col">Telefono</th>
                            <th scope="col">Correo</th>
                            <th scope="col">Fecha</th>
                        </tr>
                        </thead>
                        <tbody>
                        {searchResults.map((item) => (
                            <tr key={item.id}>
                                <td>{item.matricula}</td>
                                <td>{Number(item.metros).toFixed(1)}</td>
                                <td>{item.fullname}</td>
                                <td>{item.phone}</td>
                                <td>{item.email}</td>
                                <td>{moment(item.createdAt).format('DD/MM/YYYY')}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                </div>
                <ul className="pagination" style={{display: 'flex', justifyContent: 'center'}}>
                    <li className="page-item">
                        <button className="btn btn-outline-success"
                                onClick={prevHandler}>&lt;</button>
                    </li>
                    <li className="page-item disabled"
                        style={{marginLeft: '15px'}}>
                        <button className="btn btn-outline-dark disabled">{currentPage + 1}</button>
                    </li>
                    <li className="page-item"
                        style={{marginLeft: '15px'}}>
                        <button className="btn btn-outline-success"
                                onClick={nextHandler}>>
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
}
export default Dashboard;
