import './dashboardStyle.css';

const DashFooter = () => {

    return (
        <div className="container-fluid">
            <footer className="bg-primary-light text-center text-white">
                <div className="text-center p-3" style={{backgroundColor: 'rgba(0, 0, 0, 0.2)'}}>
                    <a className="text-white" href="https://Aliatic.com.co/" target='_blank'>© 2024  |  Aliatic.com.co</a>
                </div>
            </footer>
        </div>
    );
}

export default DashFooter;
