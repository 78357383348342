import React, { useState } from 'react';
import LoginService from '../Service/LoginService';
import './dashboardStyle.css';

const Login = (props) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);

        try {
            const user = await LoginService.login({ username, password });
            props.onSetUser(user);
            setUsername('');
            setPassword('');
        } catch (error) {
            console.error(error);
            setError(error.message);
        }
    };

    return (
        <div className="container">
            <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                            <div className="d-flex justify-content-center py-4">
                                <a href="" className="logo d-flex align-items-center w-auto">
                                    <img
                                        src="https://obligacionesurbanisticas.co/wp-content/uploads/2023/10/logo-obligaciones-urbanisticas.svg"
                                        className="w-100"
                                        alt="Icon"
                                    />
                                </a>
                            </div>
                            <div className="card mb-3">
                                <div className="card-body">
                                    <div className="pt-4 pb-2">
                                        <h5 className="card-title text-center pb-0 fs-4">Ingresar</h5>
                                        <p className="text-center small">Inicio de Sesion</p>
                                    </div>
                                    <form onSubmit={handleSubmit} className="row g-3 needs-validation" noValidate>
                                        <div className="col-12">
                                            <label htmlFor="username" className="form-label">
                                                Usuario
                                            </label>
                                            <div className="input-group has-validation">
                                                <span className="input-group-text" id="inputGroupPrepend">
                                                    @
                                                </span>
                                                <input
                                                    onChange={(e) => setUsername(e.target.value)}
                                                    value={username}
                                                    type="text"
                                                    name="username"
                                                    className="form-control"
                                                    id="username"
                                                    required
                                                />
                                                <div className="invalid-feedback">Ingresa el usuario.</div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <label htmlFor="password" className="form-label">
                                                Contraseña
                                            </label>
                                            <input
                                                onChange={(e) => setPassword(e.target.value)}
                                                value={password}
                                                type="password"
                                                name="password"
                                                className="form-control"
                                                id="password"
                                                required
                                            />
                                            <div className="invalid-feedback">Ingresa la Contraseña.</div>
                                        </div>
                                        <div className="col-12">
                                            <button className="btn btn-primary w-100" type="submit">
                                                Ingresar
                                            </button>
                                        </div>
                                        {error && (
                                            <div className="col-12 text-danger text-center">
                                                {error}
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>
                            <div className="credits">
                                Diseñado por <a href="https://aliatic.com.co">Aliatic</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Login;