import React, { useState } from 'react';
import './dashboardStyle.css';

const Header = (props) => {
    const [searchNumber, setSearchNumber] = useState('');

    const handleChange = (e) => {
        setSearchNumber(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        props.onSearch(searchNumber);
        setSearchNumber('');
    };

    return (
        <div id="header" className="header fixed-top d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
                <a href="#" className="logo d-flex align-items-center">
                    <img src="https://obligacionesurbanisticas.co/wp-content/uploads/2023/10/logo-obligaciones-urbanisticas.svg" className="d-none d-lg-block" />
                </a>
            </div>
            <div className="search-bar">
                <form className="search-form d-flex align-items-center" onSubmit={handleSubmit}>
                    <input
                        type="number"
                        placeholder="# de Matricula"
                        value={searchNumber}
                        onChange={handleChange}
                    />
                    <button type="submit"><i className="bi bi-search"></i></button>
                    <h5 style={{marginLeft: '50px'}} className="card-title"><span>Buscar por Matricula</span></h5>
                </form>
            </div>
            <form className="d-flex align-items-center justify-content-between" noValidate
                  style={{marginRight: '10px'}}>
                <button type="button" className="btn btn-outline-success" onClick={props.onLogout}>
                    Cerrar Sesión
                </button>
            </form>
        </div>
    );
}

export default Header;
