import ApiService from "./ApiService";

const LoginService = {}

//const SERVER = `http://localhost:4000`
const SERVER = `https://apisimuladorpot.aliatic.app`

const GET_LOGIN_API_URL = `${SERVER}/login`;

LoginService.login = async (credentials) => {
    const response = await fetch(GET_LOGIN_API_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    });

    if (!response.ok) {
        const error = await response.json()
        throw new Error(error.errorMessage);
    }

    return await response.json();
}

export default LoginService;