import {useState} from "react";

export default function useToken() {
    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        return JSON.parse(tokenString)
    };

    const [token, setToken] = useState(getToken);

    const saveToken = userToken => {
        localStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken);

    };

    const clearToken = () => {
        localStorage.removeItem('token');
        setToken(null)
    }

    return {
        token,
        setToken: saveToken,
        clearToken: clearToken
    }
}
