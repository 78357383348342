import './App.css';
import MainRoutes from "./Components/mainRoutes";
import { BrowserRouter as Router } from 'react-router-dom';


const App = () => {

    return (
        <Router>
            <MainRoutes />
        </Router>
    );
};

export default App;
