import { Route, Routes, Navigate } from "react-router-dom";
import Simulator from "./Simulator";
import Dashboard from "./Dashboard";
import Login from "./Login";
import useToken from "../Helpers/useToken";
import Header from "./header";
import DashFooter from "./DashFooter";
import {useState} from "react";

const MainRoutes = () => {
    const { token, setToken, clearToken } = useToken();
    const [searchTerm,setSearchTerm] = useState("");

    const setUserHandler = user => {
        console.log(user);
        setToken(user.token);
    };

    const clearUserHandler = () => {
        clearToken();
    };

    const PrivateRoute = ({ children }) => {

        return token ? children : <Navigate to="/login" />;
    };

    const LoginRoute = () => {
        return token ? <Navigate to="/dashboard" /> : <Login onSetUser={setUserHandler} />;
    };

    const SearchHandler = (searchNumber) => {
        setSearchTerm(searchNumber);
    };

    return (
        <Routes>
            <Route path="/" element={<Simulator />} />
            <Route path="/login" element={<LoginRoute />} />
            <Route path="/dashboard" element={
               <PrivateRoute>
                    <Header onLogout={clearUserHandler} onSearch={SearchHandler}/>
                    <Dashboard searchTerm={searchTerm}/>
                    <DashFooter/>
               </PrivateRoute>
            } />
        </Routes>
    );
};

export default MainRoutes;